import { useEffect } from "react"
import { NextSeo } from "next-seo"
import type { GetStaticPropsContext, InferGetStaticPropsType } from "next"

import { getSite } from "@lib/site"
import { getDictionary } from "@lib/dictionary"

import { getPage } from "@lib/page"

import { useTracking, useGeoIp } from "@hooks/index"

import { DynamicComponent } from "@components/ui"

export default function Home({
  page,
  locale
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { trackPage } = useTracking()
  const geoIp = useGeoIp()

  useEffect(() => {
    if (geoIp?.country_code) {
      trackPage({
        category: "Home",
      })
    }
  }, [geoIp])
  
  return (
    <>
      <NextSeo
        title={page.title}
        titleTemplate={page.seoTitle ? page.seoTitle : ""}
        description={page.seoDescription}
        canonical={`https://www.hautehijab.com/${locale == "en-US" ? "" : `${locale}/`}`}
        openGraph={{
          images: [
            {
              url: page?.seoImage?.url,
              alt: page?.seoImage?.description,
            },
          ],
        }}
      />
      {page.sectionsCollection.items.map((entry: any) => {
        return (
          entry?.sys?.id && (
            <DynamicComponent entry={entry} key={entry.sys.id} />
          )
        )
      })}
    </>
  )
}

export async function getStaticProps({
  preview = false,
  locale = "en-US",
}: GetStaticPropsContext) {

  const [site, dictionary, page] = await Promise.all([getSite({ preview, locale }), getDictionary({ preview, locale }), getPage({ slug: "/", preview, locale })])

  return {
    props: {
      preview,
      site,
      dictionary,
      locale,
      page,
    },
    revalidate: 300
  }
}
